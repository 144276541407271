/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var functions = {};
        functions.scroll = function(e,number) {
          if ( $(e).scrollTop() > number ) {
            $('.scroll-header').addClass('fix_menu');
          }
          else {
            $('.scroll-header').removeClass('fix_menu');
          }
        };
        $('.drawer').drawer();
        functions.scroll(window, 20);
        $(window).scroll(function() {
          functions.scroll(this, 20);
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Voucher Registration Form page, note the change from about-us to about_us.
    'voucher_registration_form': {
      init: function() {
        // JavaScript to be fired on the about us page
        var collapse = $('.group-form').find('h2'),
            divCollapse = $('.panel-collapse');
        function changeCollapse(change) {
          collapse.attr('data-toggle', change);
        }
        $(window).resize(function () {
          if($(this).outerWidth() < 767){
            changeCollapse('collapse');
            divCollapse.addClass('in');
          }else {
            changeCollapse('');
            divCollapse.addClass('in');
          }
        });
        if($(window).outerWidth() < 767){
          changeCollapse('collapse');
          divCollapse.addClass('in');
        }else {
          changeCollapse('');
          divCollapse.addClass('in');
        }

      }
    },
    // Summer Camps page
    'summer_camps': {
      init: function() {
        ( function( window ) {

          'use strict';

// class helper functions from bonzo https://github.com/ded/bonzo

          function classReg( className ) {
            return new RegExp("(^|\\s+)" + className + "(\\s+|$)");
          }

// classList support for class management
// altho to be fair, the api sucks because it won't accept multiple classes at once
          var hasClass, addClass, removeClass;

          if ( 'classList' in document.documentElement ) {
            hasClass = function( elem, c ) {
              return elem.classList.contains( c );
            };
            addClass = function( elem, c ) {
              elem.classList.add( c );
            };
            removeClass = function( elem, c ) {
              elem.classList.remove( c );
            };
          }
          else {
            hasClass = function( elem, c ) {
              return classReg( c ).test( elem.className );
            };
            addClass = function( elem, c ) {
              if ( !hasClass( elem, c ) ) {
                elem.className = elem.className + ' ' + c;
              }
            };
            removeClass = function( elem, c ) {
              elem.className = elem.className.replace( classReg( c ), ' ' );
            };
          }

          function toggleClass( elem, c ) {
            var fn = hasClass( elem, c ) ? removeClass : addClass;
            fn( elem, c );
          }

          var classie = {
            // full names
            hasClass: hasClass,
            addClass: addClass,
            removeClass: removeClass,
            toggleClass: toggleClass,
            // short names
            has: hasClass,
            add: addClass,
            remove: removeClass,
            toggle: toggleClass
          };

// transport
          if ( typeof define === 'function' && define.amd ) {
            // AMD
            define( classie );
          } else {
            // browser global
            window.classie = classie;
          }

        })( window );
        var ModalEffects = (function() {

          function init() {

            var overlay = document.querySelector( '.md-overlay' );

            [].slice.call( document.querySelectorAll( '.md-trigger' ) ).forEach( function( el, i ) {

              var modal = document.querySelector( '#' + el.getAttribute( 'data-modal' ) ),
                  close = modal.querySelector( '.md-close' ),
                  container = document.querySelector( '.wrap' ),
                  header = document.querySelector( '.banner' ),
                  footer = document.querySelector( '.content-info' ),
                  body = document.body;

              function removeModal( hasPerspective ) {
                classie.remove( modal, 'md-show' );
                classie.remove( container, 'blurs' );
                classie.remove( header, 'blurs' );
                classie.remove( footer, 'blurs' );
                classie.remove( body, 'overflow-hidden' );

                if( hasPerspective ) {
                  classie.remove( document.documentElement, 'md-perspective' );
                }
              }

              function removeModalHandler() {
                removeModal( classie.has( el, 'md-setperspective' ) );
              }

              el.addEventListener( 'click', function( ev ) {
                classie.add( modal, 'md-show' );
                classie.add( container, 'blurs' );
                classie.add( header, 'blurs' );
                classie.add( footer, 'blurs' );
                classie.add( body, 'overflow-hidden' );
                overlay.removeEventListener( 'click', removeModalHandler );
                overlay.addEventListener( 'click', removeModalHandler );

                if( classie.has( el, 'md-setperspective' ) ) {
                  setTimeout( function() {
                    classie.add( document.documentElement, 'md-perspective' );
                  }, 25 );
                }
              });

              close.addEventListener( 'click', function( ev ) {
                ev.stopPropagation();
                removeModalHandler();
              });

            } );

          }

          init();

        })();
      },
      finalize: function() {
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
